var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout",style:(_vm.bgStyle)},[_c('div',{staticClass:"background tpl_collect",style:(_vm.inputStyle)},[_c('el-form',{ref:"collectInfoForm",attrs:{"size":"small","label-position":"left","model":_vm.collectForm}},[_c('el-row',[_vm._l((_vm.res.list[0].inputList),function(item,index){return (!item.hide)?_c('el-col',{key:index,attrs:{"span":_vm.flexSpan}},[(item.type == 'text')?_c('el-form-item',[_c('el-input',{style:([
                { height: item.height },
                { 'line-height': item.height },
              ]),attrs:{"maxlength":"11","placeholder":'请输入' + item.label},model:{value:(_vm.collectForm[item.prop]),callback:function ($$v) {_vm.$set(_vm.collectForm, item.prop, $$v)},expression:"collectForm[item.prop]"}})],1):_vm._e(),(item.type == 'select')?_c('el-form-item',[_c('el-select',{style:([
                { height: item.height },
                { 'line-height': item.height },
              ]),attrs:{"placeholder":'请选择' + item.label},model:{value:(_vm.collectForm.cityCode),callback:function ($$v) {_vm.$set(_vm.collectForm, "cityCode", $$v)},expression:"collectForm.cityCode"}},_vm._l((item.dicData),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1):_vm._e()],1):_vm._e()}),_c('el-col',{attrs:{"span":_vm.flexSpan}},[_c('el-form-item',[_c('el-button',{style:([
                { padding: _vm.res.list[0].btn_padding },
                {
                  backgroundColor: _vm.res.list[0].bg_color,
                },
                {
                  borderColor: _vm.res.list[0].border_color,
                },
                {
                  borderRadius: _vm.res.list[0].border_radius + 'px',
                },
                {
                  color: this.res.list[0].color,
                },
              ]),attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.submitInfo()}}},[_vm._v("立即预约")])],1)],1)],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }