<template>
  <div class="layout">
    <view class="margin-sm">
      <!-- 注意，如果需要兼容微信小程序，最好通过setRules方法设置rules规则 -->
      <u--form labelPosition="top" :model="collectForm" ref="collectForm" labelWidth="200rpx">
        <view v-for="(item, index) in res.list" :key="index" v-if="!item.hide " class="margin-tb-sm">
          <u-form-item v-if="item.prop == 'name'" :prop="item.prop" class="form-input">
            <u--input v-model="collectForm.name"  :placeholder="'请填写'+item.label"  border="none"></u--input>
          </u-form-item>
          <u-form-item v-if="item.prop == 'mobile'" :prop="item.prop" class="form-input">
            <u--input v-model="collectForm.mobile"  :placeholder="'请填写'+item.label" border="none"></u--input>
          </u-form-item>
          
          <u-form-item v-if="item.prop == 'salesRoom'" @click="salesRoomFlag = true" :prop="item.prop" class="form-input">
            <u--input v-model="collectForm.salesRoom" disabled disabledColor="#ffffff"
              :placeholder="'请选择'+item.label" border="none" ></u--input>
            <u-icon slot="right" name="arrow-right"></u-icon>
          </u-form-item>
          
          <u-form-item v-if="item.prop == 'cityCode'" @click="cityCodeFlag = true" :prop="item.prop" class="form-input">
            <u--input v-model="collectForm.cityCode" disabled disabledColor="#ffffff"
              :placeholder="'请选择'+item.label" border="none" ></u--input>
            <u-icon slot="right" name="arrow-right"></u-icon>
          
          </u-form-item>
        </view>
      </u--form>

      <u-button type="primary" text="免费预约 实景体验" class="margin-tb-sm subscribe-btn" @click="submit"></u-button>
      <u-action-sheet :show="cityCodeFlag" keyName="label" :actions="cityCodeList" title="请选择居住地"
        @close="cityCodeFlag = false" @select="cityCodeSelect">
      </u-action-sheet>

      <u-action-sheet :show="salesRoomFlag" keyName="label" :actions="salesRoomList" title="请选择最近门店"
        @close="salesRoomFlag = false" @select="salesRoomSelect">
      </u-action-sheet>

    </view>
  </div>
</template>

<script>
  export default {
    title: "客户信息采集",
    props: ["res"],
    data() {
      return {
        direction: 'row',
        cityCodeFlag: false,
        salesRoomFlag: false,
        list: undefined,
        collectForm: {
          name: undefined, //姓名
          mobile: undefined, //手机号
          cityNo: undefined, //城市代码
          cityCode: undefined, //城市代码
          salesRoom: undefined, //门店名称
          salesRoomNo: undefined, //门店代码
        },
        cityCodeList: [],
        salesRoomList: [],
        rules: {
          name: {
            required: true,
            message: '请输入姓名',
            // 可以单个或者同时写两个触发验证方式
            trigger: ['blur']
          },
          cityCode: {
            required: true,
            message: '请选择居住地区',
            // 可以单个或者同时写两个触发验证方式
            trigger: ['change']
          },
          salesRoom: {
            required: true,
            message: '请选择门店',
            // 可以单个或者同时写两个触发验证方式
            trigger: ['change']
          },
          // 手机号
          mobile: [{
              required: true,
              message: '请输入手机号',
              trigger: ['change', 'blur'],
            }, {
              // 自定义验证函数，见上说明
              validator: (rule, value, callback) => {
                return this.$u.test.mobile(value);
              },
              message: '手机号码不正确',
              // 触发器可以同时用blur和change
              trigger: ['change', 'blur'],
            }
          ]
        }

      }
    },
    mounted() {
      this.res.list.map(i => {
        if (!i.hide) {
          if (i.prop == 'salesRoom') {
            this.salesRoomList = i.dicData;
          } else if (i.prop == 'cityCode') {
            this.cityCodeList = i.dicData;
          }
        }
      })
      //添加规则
      this.$nextTick(() => {
        this.$refs.collectForm.setRules(this.rules);
      });
    },
    methods: {
      salesRoomSelect(item) {
        this.collectForm.salesRoom = item.name
        this.collectForm.salesRoomNo = item.value
      },
      cityCodeSelect(item) {
        this.collectForm.cityCode = item.name
        this.collectForm.cityNo = item.value
      },
      // 清楚不显示输入项的form验证
      clearValidate(name) {
          if (!name) name = []
          this.$refs['collectForm'].clearValidate(name)
      },
      
      submit() {
        // 如果有错误，会在catch中返回报错信息数组，校验通过则在then中返回true
        this.$refs.collectForm.validate().then(res => {
          this.$emit("callback",  this.collectForm);
          // uni.$u.toast('校验通过'+res)
        }).catch(errors => {
          console.log("校验失败"+errors);
        })
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "./tpl.scss";

  .background {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 84rpx;
    text-align: left;
    font-size: 20rpx;
    background-size: cover;
  }

  .title {
    line-height: 84rpx;
    font-size: 20px;
    font-weight: bold;
  }
  
  .subscribe-btn{
    background-color: #fa4516;
    border-color:#fa4516;
  }
  
  .form-input{
    padding:8rpx;
    
    /deep/.u-form-item__body{
       background-color: #f2f2f2;
       padding:10rpx 0;
    }
    
    /deep/uni-input{
      line-height: 60rpx;
      height:60rpx;
      margin:0 20rpx;
      font-size:28rpx;
    }
    /deep/.u-input__content__field-wrapper{
      background-color: #f2f2f2;
    }
  }
</style>
