<template>
  <div class="layout" :style="bgStyle">
    <div class="background tpl_collect" :style="inputStyle">
      <el-form
        size="small"
        label-position="left"
        ref="collectInfoForm"
        :model="collectForm"
      >
        <el-row>
          <el-col
            v-for="(item, index) in res.list[0].inputList"
            :key="index"
            v-if="!item.hide"
            :span="flexSpan"
          >
            <el-form-item v-if="item.type == 'text'">
              <el-input
                :style="[
                  { height: item.height },
                  { 'line-height': item.height },
                ]"
                maxlength="11"
                :placeholder="'请输入' + item.label"
                v-model="collectForm[item.prop]"
              />
            </el-form-item>
            <el-form-item v-if="item.type == 'select'">
              <el-select
                :style="[
                  { height: item.height },
                  { 'line-height': item.height },
                ]"
                v-model="collectForm.cityCode"
                :placeholder="'请选择' + item.label"
              >
                <el-option
                  v-for="item in item.dicData"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="flexSpan">
            <el-form-item>
              <el-button
                :style="[
                  { padding: res.list[0].btn_padding },
                  {
                    backgroundColor: res.list[0].bg_color,
                  },
                  {
                    borderColor: res.list[0].border_color,
                  },
                  {
                    borderRadius: res.list[0].border_radius + 'px',
                  },
                  {
                    color: this.res.list[0].color,
                  },
                ]"
                type="primary"
                size="small"
                @click="submitInfo()"
                >立即预约</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { postmobile } from "@/api/views/name.js";

export default {
  title: "广告获客",
  props: ["res", "pageDescription"],
  data() {
    return {
      index: 0,
      context: "",
      flexSpan: 24,
      displayFlex: undefined,
      adNo: [],
      collectForm: {
        bizSource: "PC", //业务来源，（H5，PC，微信小程，支付宝小程，百度小程序等）
        mediaCode: undefined, //媒体来源代码
        channelCode: undefined, //渠道代码
        activityCode: undefined, //营销活动代码
        projectCode: undefined, //广告计划
        adUnitCode: undefined, //广告单元代码
        name: undefined, //姓名
        mobile: undefined, //手机号
        city: undefined, //城市代码
        cityCode: undefined, //城市代码
        salesRoom: undefined, //门店名称
        salesRoomNo: undefined, //门店代码
        visitorId: undefined, //访客ID
        operatingSystem: undefined, //操作系统
        mobileModel: undefined, //手机类型
        browserType: undefined, //使用平台
        reqUrl: undefined, //当前页面的路由
        pageDescription: undefined, //当前页面的介绍
        visitorIp: null, // 提交信息的时候当前客户端的IP
      },
      rules: {
        name: {
          required: true,
          message: "请输入姓名",
          // 可以单个或者同时写两个触发验证方式
          trigger: ["blur"],
        },
        cityCode: {
          required: true,
          message: "请选择居住地区",
          // 可以单个或者同时写两个触发验证方式
          trigger: ["change"],
        },
        salesRoom: {
          required: true,
          message: "请选择门店",
          // 可以单个或者同时写两个触发验证方式
          trigger: ["change"],
        },
        // 手机号
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["change", "blur"],
          },
          {
            // 自定义验证函数，见上说明
            validate: (rule, value, callback) => {
              const reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
              if (value == "" || value == undefined || value == null) {
                callback();
              } else {
                if (!reg.test(value) && value != "") {
                  callback(new Error("请输入正确的电话号码或者固话号码"));
                } else {
                  callback();
                }
              }
            },
            // 触发器可以同时用blur和change
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  created() {
    this.displayFlex = this.res.list[0].displayFlex;
    this.calFlex();
    // 从query上面获取到四个code值
    this.adNo = this.$route.query.adNo;
    //渠道-媒体-营销活动-广告计划-广告单元
    var adNos = this.adNo.split("-");
    this.collectForm.channelCode = adNos[0]; //渠道代码
    this.collectForm.mediaCode = adNos[1]; //媒体代码
    this.collectForm.activityCode = adNos[2]; //营销活动代码
    this.collectForm.projectCode = adNos[3]; //广告计划
    this.collectForm.adUnitCode = adNos[4]; //广告单元代码

    // 获取剩下的提交信息
    this.collectForm.visitorId = this.$Cookie.get("MJ0INTERVIEWID"); //访客Id
    this.collectForm.operatingSystem = this.$Cookie.get("operatingSystem"); //操作系统
    this.collectForm.visitorIp = this.$Cookie.get("ip");
    this.collectForm.browserType = this.$Cookie.get("browserType"); //使用平台
    this.collectForm.reqUrl = window.location.href; ////获取当前页面的路由
  },
  mounted() {


    this.collectForm.pageDescription = this.pageDescription;
    // console.log(this.res.list[0]);
    this.res.list.map((i) => {
      if (!i.hide) {
        if (i.prop == "salesRoom") {
          this.salesRoomList = i.dicData;
        } else if (i.prop == "cityCode") {
          this.cityCodeList = i.dicData;
        }
      }
    });

    const collectInfoForm = this.$refs.collectInfoForm[0];
    if (collectInfoForm) {
      //添加规则
      this.$nextTick(() => {
        let _that = this;
        _that.$refs.collectInfoForm.setRules(_that.rules);
      });
    }
  },
  computed: {
    bgStyle() {
      var widthsf = parseInt(this.res.list[0].bg_width) / 2;
      return {
        backgroundImage: "url(" + this.res.list[0].img + ")",
        height: this.res.list[0].bg_height + "px",
        width: this.res.list[0].bg_width + "px",
        left: "50%",
        marginLeft: "-" + widthsf + "px",
        overflow: "hidden",
        position: "relative",
        marginTop: this.res.list[0].marginTop + "px",
      };
    },
    inputStyle() {
      return {
        height: this.res.list[0].height + "px",
        width: this.res.list[0].width + "px",
        marginTop: this.res.list[0].top + "px",
        marginLeft: this.res.list[0].left + "px",
      };
    },
  },
  watch: {
    res: {
      handler(newVal, oldVal) {
        this.calFlex();
      },
      deep: true,
    },
  },
  methods: {
    // 清楚不显示输入项的form验证
    clearValidate(name) {
      if (!name) name = [];
      this.$refs["collectInfoForm"].clearValidate(name);
    },
    submitInfo() {
      let _that = this;
      if (this.collectForm.mobile == undefined) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
          center: true,
          offset: 400,
          duration: 2000,
          customClass: "message-box19",
        });
      } else {
        if (!this.collectForm.mobile.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
          this.$message.closeAll();
          this.$message({
            message: "请输入正确的手机号",
            type: "warning",
            center: true,
            offset: 400,
            duration: 2000,
            customClass: "message-box19",
          });
          this.collectForm.name = "";
          this.collectForm.mobile = "";
        } else {
          console.log("提交数据---->");
          this.$refs.collectInfoForm.validate((res) => {
            console.log(res);
            _that.$emit("callback", this.collectForm);
            console.log(this.collectForm);
            postmobile(this.collectForm).then(() => {
              console.log("提交成功");
              this.$message.closeAll();
              this.$message({
                message: "提交成功！稍后家装顾问与您联系，请注意021开头电话",
                center: true,
                offset: 400,
                duration: 2000,
                customClass: "message-box19",
              });
            });
            this.collectForm.name = "";
            this.collectForm.mobile = "";
          });
        }
      }
    },

    calFlex() {
      //计算行列
      if ("uniline" === this.res.list[0].displayFlex) {
        let fs = 0;
        this.res.list[0].inputList.forEach((il) => {
          if (il.hide) {
            fs += 1;
          }
        });
        if (fs != 0) this.flexSpan = 24 / (fs + 2);
      } else {
        this.flexSpan = 24;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./tpl.scss";
@import "../../../css/index.css";
.tpl_collect {
  font-size: 12px !important;

  /deep/.el-form-item__label {
    font-size: 12px !important;
  }
  /deep/.el-radio__label {
    font-size: 12px !important;
  }

  .el-col {
    padding: 6px;
  }

  /deep/.el-input__inner {
    height: 100%;
    background: #f7f7f7;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    margin-right: 3%;
    margin-top: 2px;
  }
  /deep/.el-button {
    height: 100%;
  }
}
.background {
  z-index: 2;
  width: 100%;
  text-align: left;
  font-size: 12px;
  background-size: cover;
}

.agreement_link_wrapper {
  line-height: 45px;
  .agreement_link_text {
    font-size: 12px;
    line-height: 0.37333333rem;
    color: #999;
  }
}
</style>
