<template>
  <!-- 备案号 -->
  <el-row class="record" style="clear: both">
    <el-col :span="6.5" style="clear: both">
      <p @click="handel">
        Copyrights&nbsp;©&nbsp;HSMJ,All&nbsp;rights&nbsp;reserved.沪ICPC备2022017450号-2上海境喜家居科技有限公司&nbsp;&nbsp;
      </p>
    </el-col>
    <div class="clear"></div>
    <el-col :span="0.1">
      <img class="img1" src="../image/jinghui.png" alt="" />&nbsp;&nbsp;
    </el-col>
    <el-col :span="17.4">
      <p @click="change()">沪公网安备31011202011344号</p>
    </el-col>
  </el-row>
</template>
<script>
export default {
  methods: {
    handel() {
      window.open("http://beian.miit.gov.cn", "_blank");
    },
    change() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202011344",
        "_blank"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.record {
  background-color: #000;
  // width: 1920px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 10px;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.5;
    line-height: 17px;
    cursor: pointer;
  }
  .img1 {
    width: 18px;
    height: 20px;
  }
}
</style>
