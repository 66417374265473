<template>
  <div :style="{ background: bg_color }" class="active">
    <!-- uni 中不能使用 vue component 所以用if判断每个组件 -->
    <div v-for="(item, index) in pageData.list" :key="index">
      <topAd v-if="item.type == 'topAd'" :res="item.options" />
      <div class="wrapper" :style="wrapperStyle(item.options.list[0])">
        <top-menu v-if="item.type == 'topMenu'" :res="item.options" />
        <carousel v-if="item.type == 'carousel'" :res="item.options" />
        <adCollect v-if="item.type == 'adCollect'" :res="item.options"
          :pageDescription="getCustomerData.pageDescription" />
        <leftOneRightTwo v-if="item.type == 'leftOneRightTwo'" :res="item.options" />
        <leftTwoRightOne v-if="item.type == 'leftTwoRightOne'" :res="item.options" />
        <topOneBottomTwo v-if="item.type == 'topOneBottomTwo'" :res="item.options" />
        <topTwoBottomOne v-if="item.type == 'topTwoBottomOne'" :res="item.options" />
        <flexThree v-if="item.type == 'flexThree'" :res="item.options" />
        <flexFive v-if="item.type == 'flexFive'" :res="item.options" />
        <flexFour v-if="item.type == 'flexFour'" :res="item.options" />
        <flexTwo v-if="item.type == 'flexTwo'" :res="item.options" />
        <textPicture v-if="item.type == 'textPicture'" :res="item.options" />
        <flexOne v-if="item.type == 'flexOne'" :res="item.options" />
        <adFlexOne :pageDescription="getCustomerData.pageDescription" v-if="item.type == 'adFlexOne'" :res="item.options"
          @callback="handelPopo" />
        <footer_l v-if="item.type == 'footer'" :res="item.options" :pageDescription="getCustomerData.pageDescription" />
        <ad_button v-if="item.type == 'ad_button'" :res="item.options" />
        <!-- 收集用户信息，包括手机号，姓名，家庭地址，住房面积等 -->
        <tplCollect v-if="item.type == 'collect'" @callback="subscribeCustomerInfo" :res="item.options" />
      </div>
    </div>
    <!-- 下面是弹窗 -->
    <transition name="el-fade-in">
      <div class="prop" v-if="flag">
        <div class="close" @click="close()">
          <img src="http://mjgw.oss-cn-shanghai.aliyuncs.com/upload/2022-08-31/c52e0f1e666647e391488b12cb48c66a.png"
            alt="" />
        </div>
        <div :style="[
          {
            backgroundImage: 'url(' + propUrl + ')',
          },
        ]" class="prop-top"></div>
        <div class="prop-bottom">
          <div class="ipt1">
            <span class="txt1 f14">您的称呼：</span>
            <input class="file f14" type="text" v-model="getCustomerData.name" maxlength="11" />
          </div>
          <div class="ipt2">
            <span class="txt1 f14">手机号码：</span>
            <input class="file f14" type="text" maxlength="11" v-model="getCustomerData.mobile" />
          </div>
          <div @click="submitInfo" :style="propBtnStyle" class="txt">
            {{ propBtn.name }}
          </div>
        </div>
      </div>
    </transition>
    <record />
  </div>
</template>

<script>
// 引用组件
import tpl_banner from "@/views/active/template/tpl_banner"; //导航栏模块
import tpl_collect from "@/views/active/template/tpl_collect"; //用户信息收集
import tpl_left_one_right_two from "@/views/active/template/tpl_left_one_right_two"; //左一右二模块
import tpl_left_two_right_one from "@/views/active/template/tpl_left_two_right_one"; //左二右一模块
import tpl_top_one_bottom_two from "@/views/active/template/tpl_top_one_bottom_two"; //上一下二模块
import tpl_top_two_bottom_one from "@/views/active/template/tpl_top_two_bottom_one"; //上二下一模块
import tpl_flex_one from "@/views/active/template/tpl_flex_one"; //单行图片模块
import tpl_flex_two from "@/views/active/template/tpl_flex_two"; //两张横图模块
import tpl_flex_three from "@/views/active/template/tpl_flex_three"; //三列单行图片模块
import tpl_flex_five from "@/views/active/template/tpl_flex_five"; //五列单行图片模块
import tpl_flex_four from "@/views/active/template/tpl_flex_four"; //四列单行图片模块

import tpl_notice from "@/views/active/template/tpl_notice"; //标题栏模块
import tpl_top_menu from "@/views/active/template/tpl_top_menu"; //标题栏模块
import tpl_ad_collect from "@/views/active/template/tpl_ad_collect.vue";
import tpl_ad_button from "@/views/active/template/tpl_ad_button.vue";

import tpl_top_ad from "@/views/active/template/tpl_top_ad"; //标题栏模块

import tpl_ad_flex_one from "@/views/active/template/tpl_ad_flex_one"; //广告单页面

import tpl_footerAdvert from "@/views/active/template/tpl_footerAdvert"; // 底部获客栏

import { getPageData } from "@/api/portal/page-data.js";

import record from "@/components/record.vue";

import { postmobile } from "@/api/views/name.js";
export default {
  props: {
    pageNo: {
      type: String,
      default: "",
    },
    adNo:{
        type: String,
        default: "",
    }
    //渠道-营销活动-广告计划-广告单元
  },

  data() {
    return {
      pageData: "", //楼层页面数据
      isIos: "",
      bg_color: "#e9e9e9",
      flag: false,
      propUrl: "",
      titles: {},
      getCustomerData: {
        bizSource: "PC", //业务来源，（H5，PC，微信小程，支付宝小程，百度小程序等）
        mediaCode: undefined, //媒体来源代码
        channelCode: undefined, //渠道代码
        activityCode: undefined, //营销活动代码
        projectCode: undefined, //广告计划
        adUnitCode: undefined, //广告单元代码
        name: undefined, //姓名
        mobile: undefined, //手机号
        city: undefined, //城市代码
        province: undefined, //省份
        cityCode: undefined, //城市代码
        salesRoom: undefined, //门店名称
        salesRoomNo: undefined, //门店代码
        visitorId: undefined, //访客ID
        operatingSystem: undefined, //操作系统
        mobileModel: undefined, //手机类型
        browserType: undefined, //使用平台
        reqUrl: undefined, //当前页面的路由
        pageDescription: undefined, // 当前页面的介绍
        visitorIp: null, // 提交信息的时候当前客户端的IP
      },
    };
  },
  components: {
    footer_l: tpl_footerAdvert,
    carousel: tpl_banner,
    tplCollect: tpl_collect,
    leftOneRightTwo: tpl_left_one_right_two,
    leftTwoRightOne: tpl_left_two_right_one,
    topOneBottomTwo: tpl_top_one_bottom_two,
    topTwoBottomOne: tpl_top_two_bottom_one,
    flexThree: tpl_flex_three,
    flexFive: tpl_flex_five,
    flexFour: tpl_flex_four,
    flexTwo: tpl_flex_two,
    flexOne: tpl_flex_one,
    notice: tpl_notice,
    topMenu: tpl_top_menu,
    adCollect: tpl_ad_collect,
    topAd: tpl_top_ad,
    adFlexOne: tpl_ad_flex_one,
    ad_button: tpl_ad_button,
    record,
  },
  created() {
   
  },
  mounted() { 
    this.init();
    // 获取剩下的提交信息
    this.getCustomerData.visitorId = this.$Cookie.get("MJ0INTERVIEWID"); //访客Id
    this.getCustomerData.operatingSystem = this.$Cookie.get("operatingSystem"); //操作系统
    this.getCustomerData.visitorIp = this.$Cookie.get("ip");
    this.getCustomerData.browserType = this.$Cookie.get("browserType"); //使用平台
    this.getCustomerData.reqUrl = window.location.href; ////获取当前页面的路由
  },
  computed: {
    propBtnStyle() {
      return {
        backgroundImage: "url(" + this.propBtn.propBtnUrl + ")",
        width: this.propBtn.bg_width + "px",
        height: this.propBtn.bg_height + "px",
        color: this.propBtn.color,
        fontSize: this.propBtn.font_size + "px",
        borderRadius: this.propBtn.border_radius + "px",
      };
    },
  },
  methods: {
    handelPopo(val) {
      this.flag = val;
    },
    close() {
      this.flag = false;
    },
    /**
     * 实例化首页数据楼层
     */
    init() {
      // console.log("请求数据 -->" + this.pageNo);
      this.pageData = "";
      let re = "";
      console.log("this.pageNo",this.pageNo);
      getPageData(this.pageNo).then((res) => {
        if (res.code == 0) {
          document.title = res.data.title;
          this.getCustomerData.pageDescription = res.data.description;
          this.pageData = JSON.parse(res.data.pageData);
          this.pageData.list.forEach((element) => {
            for (let key in element) {
              if (element[key] == "topAd") {
                // console.log(element.options.list[0].bg_color);
                this.bg_color = element.options.list[0].bg_color;
              }
              if (element[key] == "adFlexOne") {
                this.propUrl = element.options.list[0].propStyle.propUrl;
                this.propBtn = element.options.list[0].propStyle;
              }
            }
          });
        }
      });
      //转换代码
      if (this.adNo) {
        //渠道-媒体-营销活动-广告计划-广告单元
        var adNos = this.adNo.split("-");
        this.getCustomerData.channelCode = adNos[0]; //渠道代码
        this.getCustomerData.mediaCode = adNos[1]; //媒体代码
        this.getCustomerData.activityCode = adNos[2]; //营销活动代码
        this.getCustomerData.projectCode = adNos[3]; //广告计划
        this.getCustomerData.adUnitCode = adNos[4]; //广告单元代码
      }
    },

    wrapperStyle(item) {
        console.log("item",item);
      var mtop =
        item.marginTop === "" || item.marginTop === undefined
          ? "0"
          : item.marginTop;
      for (let key in item) {
        if (item[key] == "1920*600") {
          var mwidth = 1903;
        } else {
          var mwidth =
            item.bg_width === "" || item.bg_width === undefined
              ? "1200"
              : item.bg_width;
        }
      }
      return {
        marginTop: mtop + "px",
        width: mwidth + "px",
      };
    },
    submitInfo() {
      let _that = this;
      // console.log(this.collectForm.mobile);
      if (this.getCustomerData.mobile == undefined) {
        this.$message.closeAll();
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
          center: true,
          offset: 400,
          duration: 2000,
          customClass: "message-box19",
        });
      } else if (
        !this.getCustomerData.mobile.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)
      ) {
        this.$message.closeAll();
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
          center: true,
          offset: 400,
          duration: 2000,
          customClass: "message-box19",
        });
        this.getCustomerData.name = "";
        this.getCustomerData.mobile = "";
      } else {
        postmobile(this.getCustomerData).then(() => {
          console.log("提交成功");
          this.$message.closeAll();
          this.$message({
            message: "提交成功！稍后家装顾问与您联系，请注意021开头电话",
            center: true,
            offset: 400,
            duration: 2000,
            customClass: "message-box19",
          });
          this.flag = false;
        });
        this.getCustomerData.name = "";
        this.getCustomerData.mobile = "";
      }
    },
    /**
     * 提交获客数据信息
     * @param {Object} collectForm
     */
    subscribeCustomerInfo(collectForm) {
      // console.log("subscribeCustomerInfo  --->" + collectForm);
      this.getCustomerData.name = collectForm.name;
      this.getCustomerData.mobile = collectForm.mobile;
      this.getCustomerData.city = collectForm.cityCode;
      this.getCustomerData.salesRoomNo = collectForm.salesRoomNo;
      //系统数据

      this.getCustomerData.visitorId = this.$Cookie.get("MJ0INTERVIEWID"); //访客Id
      this.getCustomerData.operatingSystem =
        this.$Cookie.set("operatingSystem"); //操作系统
      // this.getCustomerData.mobileModel = res.model; //手机类型
      this.getCustomerData.browserType = this.$Cookie.set("browserType"); //使用平台

      this.getCustomerData.reqUrl = window.location.href; ////获取当前页面的路由
      this.$http.postmobile(this.getCustomerData).then((res) => {
        if (res.code == "0") {
          uni.$u.toast("感谢您的信任，我们会尽快安排工作人员联系您。");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  margin: 0 auto;
  overflow: hidden;
}

.prop {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px;
  height: 600px;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-out;

  .close {
    width: 68px;
    height: 68px;
    background: #ffffff;
    opacity: 0.9;
    border-radius: 50%;
    position: absolute;
    top: -34px;
    right: -34px;
    cursor: pointer;
  }

  .prop-top {
    width: 560px;
    height: 154px;
    border-radius: 12px 12px 0 0;
    background-size: 100%, 100%;
  }

  .prop-bottom {
    width: 560px;
    height: 446px;
    background: #fff;
    border-radius: 0 0 12px 12px;
    padding: 50px 0 0 40px;
    box-sizing: border-box;
    position: relative;

    .ipt1 {
      width: 481px;
      height: 81px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      position: absolute;
      top: 50px;
      left: 40px;

      .txt1 {
        width: 145px;
        height: 26px;
        font-weight: 400;
        color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 27px;
        left: 29px;
        font-size: 28px;
      }

      span {
        width: 340px;
        height: 24px;
        font-weight: 400;
        color: #656565;
        line-height: 24px;
        display: inline-block;
        position: absolute;
        top: 304px;
        left: 0px;
      }

      .file {
        width: 268px;
        font-weight: 400;
        color: #999797;
        line-height: 24px;
        font-size: 28px;
        outline: none;
        position: absolute;
        top: 27px;
        left: 165px;
        border: none;
      }
    }

    .ipt2 {
      width: 481px;
      height: 81px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      position: absolute;
      top: 180px;
      left: 40px;

      .txt1 {
        width: 145px;
        height: 26px;
        font-weight: 400;
        color: #666666;
        font-size: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 27px;
        left: 29px;
      }

      span {
        width: 340px;
        height: 24px;
        font-weight: 400;
        color: #656565;
        line-height: 24px;
        display: inline-block;
        position: absolute;
        top: 304px;
        left: 0px;
      }

      .file {
        width: 268px;
        font-weight: 400;
        color: #999797;
        line-height: 24px;
        font-size: 28px;
        outline: none;
        position: absolute;
        top: 27px;
        left: 165px;
        border: none;
      }
    }

    .txt {
      width: 480px;
      height: 80px;
      margin: 262px 0 0 0;
      border-radius: 10px;
      font-size: 36px;
      
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }
  }
}

.top-menu {
  height: 80px;
}
</style>
