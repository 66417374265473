<template>
  <div class="layout">
    <view class="margin-sm">
      <!-- 注意，如果需要兼容微信小程序，最好通过setRules方法设置rules规则 -->
      <u--form labelPosition="top" :model="collectInfoForm" :rules="rules" ref="collectInfoForm">
        <view class="margin-tb-sm">
          <u-form-item class="form-input">
            <u--input v-model="collectInfoForm.name"  placeholder="请填写姓名"  border="none"></u--input>
          </u-form-item>
          <u-form-item class="form-input">
            <u--input v-model="collectInfoForm.mobile"  max-length="11"  placeholder="请填写手机号" border="none"></u--input>
          </u-form-item>
        </view>
      </u--form>
      <u-button type="primary" text="免费预约 实景体验" class="margin-tb-sm subscribe-btn" @click="submitInfo"></u-button>
    </view>
  </div>
</template>

<script>
  export default {
    title: "客户信息采集",
    data() {
      return {
        direction: 'row',
        collectInfoForm: {
          name: undefined, //姓名
          mobile: undefined, //手机号
        },
        rules: {
          name: {
            required: true,
            message: '请输入姓名',
            // 可以单个或者同时写两个触发验证方式
            trigger: ['blur']
          },
          // 手机号
          mobile: [{
              required: true,
              message: '请输入手机号',
              trigger: ['change', 'blur'],
            }, {
              // 自定义验证函数，见上说明
              validator: (rule, value, callback) => {
                return this.$u.test.mobile(value);
              },
              message: '手机号码不正确',
              // 触发器可以同时用blur和change
              trigger: ['change', 'blur'],
            }
          ]
        }

      }
    },
    onReady() {
      let _that = this;
      
      //添加规则
      _that.$nextTick(() => {
        _that.$refs.collectInfoForm.setRules(_that.rules);
      });
    },
    mounted() {
      // let _that = this;
      // //添加规则
      // _that.$nextTick(() => {
      //   _that.$refs.collectInfoForm.setRules(_that.rules);
      // });
    },
    methods: {
      
      // 清楚不显示输入项的form验证
      clearValidate(name) {
         let _that = this;
          if (!name) name = []
          _that.$refs['collectInfoForm'].clearValidate(name)
      },
      
      submitInfo() {
        let _that = this;
        // 如果有错误，会在catch中返回报错信息数组，校验通过则在then中返回true
        console.log("提交数据---->")+'123';
        _that.$refs.collectInfoForm.validate().then(res => {
          _that.$emit("callback",  _that.collectInfoForm);
          // uni.$u.toast('校验通过'+res)
        }).catch(errors => {
          console.log("校验失败"+errors);
        })
      },
    },
  };
</script>
<style lang="scss" scoped>
  .background {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 84rpx;
    text-align: left;
    font-size: 20rpx;
    background-size: cover;
  }

  .title {
    line-height: 84rpx;
    font-size: 20px;
    font-weight: bold;
  }
  
  .subscribe-btn{
    background-color: #fa4516;
    border-color:#fa4516;
  }
  
  .form-input{
    padding:8rpx;
    
    /deep/.u-form-item__body{
       background-color: #f2f2f2;
       padding:10rpx 0;
    }
    
    /deep/uni-input{
      line-height: 60rpx;
      height:60rpx;
      margin:0 20rpx;
      font-size:28rpx;
    }
    /deep/.u-input__content__field-wrapper{
      background-color: #f2f2f2;
    }
  }
</style>
