<template>
  <div class="layout">
    <div v-if="res.list.length == 1" class="ad_button_layout" :style="bgStyle">
      <div class="ad_button_content">
        <el-button
          type="danger"
          :style="buttonStyle(res.list[0])"
          @click="submit"
          >{{ res.list[0].name }}</el-button
        >
      </div>
    </div>
    <div v-if="res.list.length == 2" class="ad_button_layout" :style="bgStyle">
      <div class="ad_button_content">
        <el-button
          type="danger"
          :style="buttonStyle(res.list[0])"
          @click="submit"
          >{{ res.list[0].name }}</el-button
        >
      </div>
      <div class="ad_button_content">
        <el-button
          type="danger"
          :style="buttonStyle(res.list[1])"
          @click="submit"
          >{{ res.list[1].name }}</el-button
        >
      </div>
    </div>

    <el-dialog :visible.sync="adCollectInfoFlag">
      <span
        text="报名立享优惠服务"
        type="warning"
        align="center"
        lineHeight="40px"
        size="28"
      />
      <div>
        <ad_collect_info @callback="collectInfoCallback" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ad_collect_info from "@/components/ad-collect-info.vue";

import { modelNavigateTo } from "./tpl";
export default {
  title: "广告按钮",
  props: ["res"],
  components: {
    ad_collect_info,
  },
  computed: {
    bgStyle() {
      return {
        width: this.res.list[0].width + "px",
        padding: this.res.list[0].bg_padding,
      };
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      adCollectInfoFlag: false,
    };
  },
  methods: {
    buttonStyle(item) {
      return {
        padding: this.res.list[0].bt_padding,
        borderColor: this.res.list[0].border_color,
        borderRadius: this.res.list[0].border_radius + "px",
        backgroundColor: this.res.list[0].bg_color,
        color: this.res.list[0].color,
      };
    },

    submit() {
      this.adCollectInfoFlag = true;
    },

    collectInfoCallback(item) {
      console.log(item);
      let _that = this;
      console.log("collectInfoCallback -->" + item);
      _that.$emit("callback", item);
      this.adCollectInfoFlag = false;
    },

    open() {
      this.adCollectInfoFlag = true;
    },
    close() {
      this.adCollectInfoFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/index.css";

.background {
  z-index: 2;
  width: 100%;
  text-align: left;
  font-size: 12px !important;
  background-size: cover;
  display: flex;
}

.ad_button_layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 6px 0;
  align-items: center; //垂直居中
  background-color: #fff;
}

.ad_button_content {
  display: flex;
  height: 60%;
  width: 100%;
  align-items: center; //适合单行显示的组件，垂直居中
  justify-content: center; //水平居中

  .ad-button {
    padding: 9px 20px;
    font-size: 12px;
    text-align: center;
  }
}
</style>

