<template>
  <div class="layout">
    <div class="box" :style="bgStyle">
      <img class="img" :style="bgStyle1" :src="this.res.list[0].img" alt="" />
    </div>
  </div>
</template>

<script>
// import drawMixin from "./mixin";
export default {
  //注册mixin
  // mixins: [drawMixin],
  title: "获客页面顶部图片",
  props: ["res"],
  data() {
    return {
      index: 0,
      context: "",
      imgHeight: "",
      width: "",
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      // console.log(this.bgStyle.width / 2);
      // this.imgHeight = this.bgStyle.width / 3.2;
      // this.width = document.body.clientWidth;
    });
    window.onresize = () => {
      // this.imgHeight = document.body.clientWidth / 3.2;
      // this.width = document.body.clientWidth;
    };
  },
  computed: {
    bgStyle() {
      var widthsf = parseInt(this.res.list[0].bg_width) / 2;
      console.log("---->" + widthsf);
      return {
        height: this.res.list[0].bg_height + "px",
        // width: this.res.list[0].bg_width + "%",
        // left: "50%",
        // marginLeft: "-" + widthsf + "px",
        overflow: "hidden",
        position: "relative",
        background:this.res.list[0].bg_color

      };
    },
    bgStyle1() {
      return {
        width: this.res.list[0].bg_width + "%",
      };
    },
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "./tpl.scss";
.box{
    width: 100%;
    text-align: center;
}
.img{
    // width: 100%;
    object-fit: cover;
}
.tpl_collect {
  font-size: 12px !important;

  /deep/.el-form-item__label {
    font-size: 12px !important;
  }
  /deep/.el-radio__label {
    font-size: 12px !important;
  }

  .el-col {
    padding: 6px;
  }

  /deep/.el-input__inner {
    height: 100%;
    background: #f7f7f7;
    border-radius: 2px;
    margin-right: 8px;
    position: relative;
    margin-right: 3%;
    margin-top: 2px;
  }
  /deep/.el-button {
    height: 100%;
  }
}
.background {
  z-index: 2;
  width: 100%;
  text-align: left;
  font-size: 12px;
  background-size: cover;
}

.agreement_link_wrapper {
  line-height: 45px;
  .agreement_link_text {
    font-size: 12px;
    line-height: 0.37333333rem;
    color: #999;
  }
}
</style>
