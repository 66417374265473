<template>
  <div class="wrapper">
    <tpl v-if="pageNo" :pageNo="pageNo" :adNo="adNo" ref="tpl"  class="model-pc"/>
    <tplMob v-if="pageNoMob" :pageNo="pageNoMob" :adNo="adNoMob" ref="tplMob" class="model-mob"/>
  </div>
</template>


<script>
import tpl from "@/views/active/views.vue";
import tplMob from "@/views/active-mob/views.vue";
import { saveVisitLog } from "@/utils/visitlog.js";

export default {
  data() {
    return {
        pageNoMob: undefined,
        pageNo: undefined,
        adNo: undefined,
        adNoMob:undefined,
        isShow: false,
        background: {
            backgroundColor: "#fff",
        },
    };
  },
  mounted() {
    this.pageNoMob = this.$route.query.pageNoMob || this.$route.query.pageNo;
    this.pageNo = this.$route.query.pageNo;
    this.adNo = this.$route.query.adNo;
    this.adNoMob = this.$route.query.adNoMob || this.$route.query.adNo;
  },

  components: {
    tpl,
    tplMob
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #f2f2f2;
}
@media screen and (max-width: 899px) {
    .model-pc{
        display: none;
    }
}
@media screen and (min-width: 900px) {
    .model-mob{
        display: none;
    }
}
</style>
