<template>
  <div class="top-menu">
    <el-row class="navBar">
      <el-col :span="24">
        <div class="navBar_w" @mouseleave="leave_a">
          <div class="logo">
            <img src="../../../image/login.png" alt="" />
          </div>
          <div class="header">
            <div
              :class="
                currentIndex === index || curIndex === index
                  ? 'header-item active'
                  : 'header-item'
              "
              v-for="(item, index) in title"
              :key="index"
              @mouseenter="changeStatus(index)"
              @mouseleave="leave()"
              @click="select(index)"
              ref="site"
            >
              {{ item[0] }}
              <div class="main" ref="sit"></div>
            </div>

            <!-- 二级导航 -->
            <div class="solution" ref="solution" v-show="flag">
              <li
                v-for="(item, index) in list"
                :key="index"
                @click="gotoSolution(index)"
              >
                {{ item[0] }}
              </li>
            </div>
            <div class="solution1" ref="solution1" v-show="flag1">
              <li
                v-for="(item, index) in list1"
                :key="index"
                @click="gotoSolution1(index, 'port')"
              >
                {{ item[0] }}
              </li>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- <el-row class="navBar" ref="nav">
    <el-col>
      <div class="navBar_w">
        <a class="logo">
          <img src="../../../image/login.png" alt="" />
        </a>
        <div class="header">
          <ul>
          <li class="header-item"
            v-for="(item, index) in res.list[0].menuList"
            :key="index"
            ref="site"
          >
            {{ item.name }}
          </li>
          </ul>
        </div>
      </div>
    </el-col>
  </el-row> -->
  </div>
</template>

<script>
export default {
  title: "头部菜单",
  name: "TopMenu",
  props: ["res"],
  components: {},
  data() {
    return {
      showModal: false, // modal显隐
      selected: null, // 已选数据
      picModelFlag: false, // 选择图片modal

      flag: false,
      flag1: false,
      // age: false,
      list: [
        ["整体家装", "/finish"],
        ["个性化定制整装", "/finish/bardian"],
        ["局改焕新", "/finish/decoration"],
        ["软装搭配", "/finish/solution"],
      ],
      list1: [
        ["门店地址"],
        ["企业蓝图", "/about/blueprint"],
        ["新闻动态", "/about/dynamic"],
      ],
      title: [
        ["首页", "/"],
        ["装修产品", "/finish"],
        ["实景样板间", "/product/index"],
        ["工艺建材", "/strict"],
        ["设计师", "/recruitment/index"],
        ["装修案例", "/investor/index"],
        ["关于我们", "/about"],
      ],
      currentIndex: 0,
      curIndex: null,
      decite: 0,
    };
  },
  mounted() {
    this.$refs.solution.addEventListener("mouseleave", () => {
      this.flag = false;
    });
    this.title.forEach((item) => {
      if (this.$route.path === "/") {
        this.currentIndex = 0;
      } else if (this.$route.path === "/finish") {
        this.currentIndex = 1;
      } else if (this.$route.path === "/product/index") {
        this.currentIndex = 2;
      } else if (this.$route.path === "/strict") {
        this.currentIndex = 3;
      } else if (this.$route.path === "/recruitment") {
        this.currentIndex = 4;
      } else if (this.$route.path === "/") {
        this.currentIndex = 5;
      } else if (this.$route.path === "/about") {
        this.currentIndex = 6;
      } else if (this.$route.path === "/product/detail") {
        this.currentIndex = 2;
      } else if (this.$route.path === "/investor/detail") {
        this.currentIndex = 5;
      } else if (this.$route.path === "/recruitment/architect") {
        this.currentIndex = 4;
      } else if (this.$route.path === "/about/dynamic/practical") {
        this.currentIndex = 6;
      }
      this.$refs.sit[0].style.display = "none";
      this.$refs.sit[this.currentIndex].className = "main1";
    });
    this.list.forEach((item) => {
      if (this.$route.path === "/entirety") {
        this.currentIndex = 1;
      } else if (this.$route.path === "/finish/decoration") {
        this.currentIndex = 1;
      } else if (this.$route.path === "/finish/solution") {
        this.currentIndex = 1;
      } else if (this.$route.path === "/finish/bardian") {
        this.currentIndex = 1;
      }
      if (this.currentIndex === 1) {
        this.$refs.sit[this.currentIndex].className = "main1";
      }
    });
    this.list1.forEach((item) => {
      if (this.$route.path === "/address") {
        this.currentIndex = 6;
      } else if (this.$route.path === "/about/blueprint") {
        this.currentIndex = 6;
      } else if (this.$route.path === "/about/dynamic") {
        this.currentIndex = 6;
      } else if (this.$route.path === "/originator") {
        this.currentIndex = 6;
      }
      if (this.currentIndex === 6) {
        this.$refs.sit[this.currentIndex].className = "main1";
      }
    });

    this.$refs.solution1.addEventListener("mouseleave", () => {
      if (this.curIndex === null) {
        this.flag1 = false;
      }
      console.log(this.curIndex);
    });
  },
  methods: {
    leave_a() {
      this.flag1 = false;
      this.flag = false;
    },
    leave() {
      this.curIndex = null;
    },
    changeStatus(index) {
      this.curIndex = index;
      if (this.curIndex === 1) {
        this.flag = true;
      } else {
        this.flag = false;
      }
      if (this.curIndex === 6) {
        this.flag1 = true;
      } else {
        this.flag1 = false;
      }
    },
    select(index) {
      this.$router.push(this.title[index][1]);
      // scrollIntoView(document.body, document.body, {
      //   behavior: 'smooth'
      // }).then(function () {})
      // this.currentIndex = index
    },
    gotoSolution(index, currentIndex) {
      console.log(index);
      // this.$emit('selectOne', index)
      // this.$router.push({ path: '/solution', query: { index: index } })
      this.$router.push(this.list[index][1]);
    },
    gotoSolution1(index, id) {
      // console.log(id)
      if (index === 0) {
        const select = id;
        localStorage.setItem("id", select);
        this.$router.push("/about");
      }
      // this.$emit('selectOne', index)
      // this.$router.push({ path: '/solution', query: { index: index } })
      this.$router.push(this.list1[index][1]);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../css/index.css";

.navBar {
  height: 80px;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 auto;
  background: #fff;
  z-index: 999;

  .navBar_w {
    height: 40px;
    margin: 20px auto;
  }
  .logo {
    // margin: 20px 217px 22px 33px;
    display: inline-block;
    image {
      width: 295px;
      height: 40px;
    }
  }
  .header {
    // box-sizing: border-box;
    height: 40px;
    margin-left: 49px;
    justify-content: center;
    align-items: center;
    float: right;
    display: flex;

    .icon1 {
      position: absolute;
      left: 209px;
      top: 17px;
    }
    .icon2 {
      position: absolute;
      left: 209px;
      top: 17px;
    }
    .icon3 {
      position: absolute;
      right: 13px;
      top: 17px;
    }
    .icon4 {
      position: absolute;
      right: 13px;
      top: 17px;
    }
    .solution {
      height: 191px;
      width: 176px;
      position: absolute;
      background: #fa4616;
      background-repeat: no-repeat;
      left: 429px;
      top: 80px;
      cursor: pointer;
      li {
        width: 176px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
        &:hover {
          // background-color: #2257b0;
          background: #fc9173;
        }
      }
    }
    .solution1 {
      height: 144px;
      width: 176px;
      position: absolute;
      background: #fa4616;
      background-repeat: no-repeat;
      right: 1px;
      top: 80px;
      li {
        width: 176px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
        &:hover {
          // background-color: #2257b0;
          background: #fc9173;
          cursor: pointer;
        }
      }
    }
    .header-item {
      width: 64px;
      height: 90px;
      font-size: 16px;
      margin: 0 28px;
      display: flex;
      font-weight: 400;
      color: #111111;
      line-height: 90px;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      z-index: 2;

      .main {
        display: none;
      }
      .main1 {
        width: 32px;
        height: 2px;
        background-color: rgba(250, 70, 22, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: absolute;
        top: 33px;
      }
    }
    /deep/.header-item:nth-child(3) {
      width: 80px;
    }
    .active {
      cursor: pointer;
      color: #fa4616;
      text-align: center;
      .solution {
        display: block;
      }
    }
  }
}
</style>