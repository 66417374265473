<template>
  <div class="layout">
    <div class="content">
      <div class="content-list" v-for="(item, index) in res.list" :key="index">
        <div class="main"></div>
        <div class="main1">
          <div class="out">
            <div class="set">
              <div class="text f28">全屋装修该花多少钱?</div>
              <div class="text1 f16">预算=决算&nbsp;&nbsp;一键获取装修报价</div>
            </div>
            <!-- <div class="tet f32">55678</div>
            <div class="tet1">元</div> -->
            <div class="lay">
              <div class="ipt">
                <img src="../../../image/dh -1.png" alt="" />
                <input
                  class="imp f14 ad_mobile"
                  type="text"
                  maxlength="11"
                  v-model="collectForm.mobile"
                  @input="search($event)"
                  placeholder="您的联系方式"
                />
              </div>
              <div class="ipt">
                <img src="../../../image/mianji-1.png" alt="" />
                <input
                  class="imp f14 ad_area"
                  type="text"
                  v-model="collectForm.houseArea"
                  @input="search1($event)"
                  placeholder="房屋面积"
                />
              </div>
              <!-- <button class="btn">计算报价</button> -->
              <el-button
                type="text"
                class="btn f20"
                style="
                  border: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                @click="openCenter()"
                >计算报价</el-button
              >
            </div>
          </div>
        </div>
        <div class="active-button" v-if="flag">
          <img class="active" src="@/image/active-button.png" alt="" />
          <img
            @click="handel"
            class="active-guanbi"
            src="@/image/active-guanbi.png"
            alt=""
          />
          <div class="texts">
            <div class="span09 f36">提交成功</div>
            <div class="span08 f22">
              稍后家装顾问与您联系，请 留意021开头电话
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postmobile } from "@/api/views/name.js";
export default {
  title: "底部栏",
  props: ["res", "pageDescription"],
  mounted() {
    console.log(this.pageDescription);
    this.collectForm.pageDescription = this.pageDescription;
  },
  data() {
    return {
      adNo: [],
      err: false,
      pass: true,
      flag: false,
      collectForm: {
        bizSource: "PC", //业务来源，（H5，PC，微信小程，支付宝小程，百度小程序等）
        mediaCode: undefined, //媒体来源代码
        channelCode: undefined, //渠道代码
        activityCode: undefined, //营销活动代码
        projectCode: undefined, //广告计划
        adUnitCode: undefined, //广告单元代码
        houseArea: undefined, // 房屋面积
        name: "客户", //姓名
        mobile: undefined, //手机号
        city: undefined, //城市代码
        cityCode: undefined, //城市代码
        salesRoom: undefined, //门店名称
        salesRoomNo: undefined, //门店代码
        visitorId: undefined, //访客ID
        operatingSystem: undefined, //操作系统
        mobileModel: undefined, //手机类型
        browserType: undefined, //使用平台
        reqUrl: undefined, //当前页面的路由
        pageDescription: undefined, //当前页面的介绍
        visitorIp: null, // 提交信息的时候当前客户端的IP
      },
    };
  },
  created() {
    // 从query上面获取到四个code值
    this.adNo = this.$route.query.adNo;
    //渠道-媒体-营销活动-广告计划-广告单元
    var adNos = this.adNo.split("-");
    this.collectForm.channelCode = adNos[0]; //渠道代码
    this.collectForm.mediaCode = adNos[1]; //媒体代码
    this.collectForm.activityCode = adNos[2]; //营销活动代码
    this.collectForm.projectCode = adNos[3]; //广告计划
    this.collectForm.adUnitCode = adNos[4]; //广告单元代码

    // 获取剩下的提交信息
    this.collectForm.visitorId = this.$Cookie.get("MJ0INTERVIEWID"); //访客Id
    this.collectForm.operatingSystem = this.$Cookie.get("operatingSystem"); //操作系统
    this.collectForm.visitorIp = this.$Cookie.get("ip");
    this.collectForm.browserType = this.$Cookie.get("browserType"); //使用平台
    this.collectForm.reqUrl = window.location.href; ////获取当前页面的路由
  },
  methods: {
    handel() {
      this.flag = false;
    },
    /* 模糊搜索 */
    search: function (event) {
      // 获取DOM对象取value值
      this.collectForm.mobile = event.currentTarget.value;
    },
    /* 模糊搜索 */
    search1: function (event) {
      // 获取DOM对象取value值
      this.collectForm.houseArea = event.currentTarget.value;
    },
    openCenter() {
      if (!this.collectForm.mobile.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
        this.err = true;
        this.pass = false;
      } else {
        this.err = false;
        this.pass = true;
      }
      if (this.pass === true) {
        // this.$message.closeAll();
        // this.$message({
        //   message: "提交成功！稍后家装顾问与您联系，请注意021开头电话",
        //   center: true,
        //   offset: 400,
        //   duration: 2000,
        //   customClass: "message-box19",
        // });

        // 点击提交用户信息
        var parmas = { ...this.collectForm };
        // parmas = new URLSearchParams()
        postmobile(parmas).then(() => {
          console.log("提交成功");
          this.flag = true;
          this.collectForm.mobile = "";
          this.collectForm.houseArea = "";
        });
      }
      if (this.err === true) {
        this.$message.closeAll();
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
          center: true,
          offset: 400,
          duration: 2000,
          customClass: "message-box19",
        });
        this.collectForm.mobile = "";
        this.collectForm.houseArea = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/style.css";
.layout {
  width: 1920px;
  height: 116px;
  // background: #000;
  position: fixed;
  bottom: 0px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);

  .main {
    //  虚拟的黑色背景
    // width: 1920px;
    height: 116px;
    background: #000000;
    opacity: 0.75;
    margin: 0 auto;
  }
  // 背景上面的图片
  .main1 {
    z-index: 2;
    width: 1920px;
    height: 116px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;

    .out {
      width: 1200px;
      height: 116px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      .set {
        width: 100%;
        height: 68px;
        position: absolute;
        top: 24px;
        left: 0;

        .text {
          width: 100%;
          height: 36px;
          font-weight: bold;
          color: #ffffff;
          line-height: 36px;
        }
        .text1 {
          height: 24px;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          margin-top: 8px;
        }
      }
      .tet {
        height: 40px;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        position: absolute;
        top: 38px;
        left: 330px;
      }
      .tet1 {
        height: 24px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        position: absolute;
        top: 46px;
        left: 457px;
      }
      .lay {
        width: 510px;
        height: 56px;
        position: absolute;
        top: 30px;
        left: 566px;
        display: flex;

        .ipt {
          width: 182px;
          height: 100%;
          background: #ffffff;
          border-radius: 2px;
          margin-right: 8px;
          position: relative;

          .imp {
            width: 110px;
            height: 100%;
            position: absolute;
            left: 37px;
            padding: 0;
            padding-left: 16px;
            outline: none;
            font-weight: 400;
            color: #656565;
            line-height: 24px;
            border: none;
          }
        }
        .btn {
          width: 130px;
          height: 56px;
          background: #fa4616;
          border-radius: 2px;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
        }
      }
    }
  }
  .active-button {
    position: fixed;
    left: 50%;
    top: -100%;
    transform: translate(-50%, -100%);
    width: 430px;
    height: 345px;
    background: #ffffff;
    border-radius: 12px;
    transition: opacity 0.3s, transform 0.4s, top 0.4s;

    .active-guanbi {
      position: absolute;
      top: -29px;
      right: -29px;
      cursor: pointer;
    }
    .texts {
      width: 263px;
      height: 108px;
      position: absolute;
      bottom: 38px;
      left: 84px;

      .span09 {
        width: 100%;
        height: 34px;
        font-weight: 500;
        color: #444444;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .span08 {
        width: 264px;
        height: 52px;
        font-weight: 400;
        color: #656565;
        margin-top: 22px;
        line-height: 31px;
        text-align: center;
      }
    }
  }
}
</style>