<template>
  <div class="model-carousel">
    <div class="nav-body clearfix">
      <div class="nav-content setup-content">
        <el-carousel :interval="3000" arrow="always">
          <el-carousel-item v-for="(item, index) in res.list" :key="index">
            <img :src="item.img" alt="" class="img" height="600" width="1920" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { modelNavigateTo } from "./tpl";
export default {
  title: "导航栏",
  props: ["res"],
  watch: {
    res: {
      handler(newValue, oldValue) {
        this.$set(this, "res", newValue);
      },
      deep: true,
    },
  },

  mounted() {},
  methods: {
    clickSwiper(index) {
      console.log("clickSwiper -->" + index);
      let usr = this.res.list[index];
      console.log("clickSwiper -->" + usr);
      modelNavigateTo(this.res.list[index]);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./tpl.scss";
@import "../../../css/index.css";

.nav-body {
  width: 1920px;
  height: 600px;
}
.model-carousel {
  width: 1920px;
  height: 600px;
}
/deep/.el-carousel__container {
  height: 600px;
}
/deep/.el-carousel {
  width: 1920px;
}
</style>
